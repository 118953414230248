// import React from 'react';
// import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// import Home from "./components/Home";
// import CreatorPage from "./components/CreatorPage";
// import AdminDashboard from './components/AdminDashboard';
// import MessageStatus from './components/MessageStatus';
// import ProtectedRoute from './components/ProtectedRoute';
// import HealthCheck from './components/HealthCheck'; // Import the HealthCheck component

// const App = () => {
//   return (
//     <BrowserRouter>
//       {/* <HealthCheck /> */}
//       <Routes>
//          <Route path="/" exact element={<Home />} />
//         <Route path="/:admin_name" exact element={<Home />} />
//         <Route path="/form/:admin_name" exact element={<CreatorPage />} />
//         <Route path="/admin/:admin_name" exact element={<ProtectedRoute element={AdminDashboard} />} />
//         <Route path="/message/:id" exact element={<MessageStatus />} />
//         <Route path="*" element={<Navigate to="/" />} />
//       </Routes>
//     </BrowserRouter>
//   );
// }

// export default App;


// App.js
import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./components/Home";
import CreatorPage from "./components/CreatorPage";
import AdminDashboard from './components/AdminDashboard';
import MessageStatus from './components/MessageStatus';
import ProtectedRoute from './components/ProtectedRoute';
import HealthCheck from './components/HealthCheck'; // Import the HealthCheck component
import SupportPage from './components/SupportPage';


const App = () => {
  return (
    <BrowserRouter>
      <HealthCheck /> {/* Add the HealthCheck component here */}
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/:admin_name" exact element={<Home />} />
        <Route path="/form/:admin_name" exact element={<CreatorPage />} />
        <Route path="/admin/:admin_name" exact element={<ProtectedRoute element={AdminDashboard} />} />
        <Route path="/message/:id" exact element={<MessageStatus />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/support" exact element={<SupportPage />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
