import React from 'react';


const InfoCard = ({ title, description, imageUrl, cardClass }) => {
    return (
        <>
            <div className={`info-card ${cardClass}`}>
                <h2>{title}</h2>
                <p>{description}</p>
                {imageUrl && <img src={imageUrl} alt="Description visual" className="info-card-image" />}
            </div>

            <style jsx>{`
                .info-card {
                    text-align: center;
                    background: #1b1b20b6;
                    color: #fff;
                    padding-bottom: 25px;
                    margin: 0 auto;
                    border-radius: 30px;
                    width: 70%;
                    border-style: solid;
                    border: .3px solid #333;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
                    height: 665px;
                    box-sizing: border-box;
                    margin-bottom: 70px;
                }

                .info-card h2 {
                font-family: 'Inter', sans-serif;
                font-weight: 550;
                    font-size: 3.7rem;
                    color: #fff;
                    margin-bottom: 20px;
                    margin-right: 15%;
                    margin-left: 15%;
                    text-align: center;
                }


                .info-card p {
                font-family: 'Inter', sans-serif;
                font-weight: 300;
                    font-size: 1.4rem;

                    line-height: 1.5;
                    color: #ccc;
                    margin-right: 15%;
                    margin-left: 15%;
                    
                }

                .info-card-image {
                     max-width:100%;
                    height: 45%;
                     margin-top: 10px;
                }

                /* Specific classes for each card */
                .card-one {
                }

                .card-one h2 {
                    font-size: 4rem; /* Larger font size for heading */

                }

                .card-one p {

                }

                .card-one .info-card-image {
                             max-width:100%;
                    height: 45%;
                }

                .card-two {
                }

                .card-two h2 {
                }

                .card-two p {
                    font-size: 1.5rem; /* Slightly larger font size */
                }

                .card-two .info-card-image {
                    max-width:100%;
                    height: 45%;
                }


                .card-three {
                    height: 595px;

                }

                .card-three h2 {
                    font-size: 3.8rem; /* Larger font size for heading */
                }

                .card-three p {
                }

                .card-three .info-card-image {
                    max-width: 100%;
                    height: 35%;
                }






               
                 @media only screen and (max-height: 600px), (max-width: 1300px) {
                 .card-three {
                    height: 495px;

                }
                .info-card h2 {
                    font-size: 2.9rem;
                    margin-right: 15%;
                    margin-left: 15%;
                 
                }

                .info-card p {
                    font-size: 1.2rem;
                    margin-right: 10%;
                    margin-left: 10%;

                    
                }
                .card-two .info-card-image {
                    max-width: 100%;
                    height: 45%;
                }
                   .card-one .info-card-image {
                    max-width: 100%;
                    height: 50%;
                }
                    .card-three .info-card-image {
                    mar
                    max-width: 100%;
                    height: 40%;
                }
            }

            
                 @media only screen and  (max-width: 700px)  {
                .info-card h2 {
                    font-size: 2rem;
                    margin-left: 5%;
                    margin-right: 5%;
                    text-align: center;

                 
                }

                .info-card p {
                    font-size: .9rem;
                    
                    margin-left: 5%;
                    margin-right: 5%;
                    text-align: center;

                    
                }
                .info-card {
                    padding-bottom: 10px;
                    height: 515px;
                }
                 .card-two info-card-image {
                    height: 30%;
                    width:80%;

                }
                    .card-three info-card-image {
                    height: 15%;
                    width:85%;

                }

                .card-one{
                    margin-top: -90px;
                }
                    .card-three {
                    height: 395px;

                }

            }
               
            @media only screen and (max-width: 400px) {
            .info-card{
            width: 85%;
            
            }
             .card-two{
                height: 465px;    
            }
    .card-two .info-card-image {
        height: 41% !important;
        width: 75% !important;
    }
        .card-one .info-card-image {
        height: 45% !important;
        width: 75% !important;
    }
    .card-three .info-card-image {
        height: 25% !important;
        width: 32% !important;
    }
    .card-one {
        margin-top: -120px !important;
    }
    .card-three {
        height: 395px !important;
    }
}



             



            `}</style>
        </>
    );
}

export default InfoCard;
