// HealthCheck.js
import React, { useEffect } from 'react';

const HealthCheck = () => {
    useEffect(() => {
        console.log('HealthCheck useEffect running');

        // Function to ping the backend using fetch
        const keepAlive = async () => {
            console.log('Pinging backend...');
            try {
                const response = await fetch('https://reachme.onrender.com/health-check');
                if (response.ok) {
                    const data = await response.json();
                    console.log('Backend is active:', data);
                } else {
                    console.error('Failed to reach backend:', response.status);
                }
            } catch (error) {
                console.error('Error pinging backend:', error);
            }
        };

        // Ping the backend every 15 seconds
        const intervalId = setInterval(keepAlive, 15 * 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return null; // This component doesn't render anything visible
};

export default HealthCheck;






// console.log('Home component rendering');
// useEffect(() => {
//     console.log('useEffect running');

//     // Function to ping the backend using fetch
//     const keepAlive = async () => {
//         console.log('Pinging backend...');
//         try {
//             const response = await fetch('https://reachme.onrender.com/health-check');
//             if (response.ok) {
//                 const data = await response.json();
//                 console.log('Backend is active:', data);
//             } else {
//                 console.error('Failed to reach backend:', response.status);
//             }
//         } catch (error) {
//             console.error('Error pinging backend:', error);
//         }
//     };

//     // Ping the backend every 15 seconds
//     const intervalId = setInterval(keepAlive, 60 * 1000);

//     // Clean up the interval on component unmount
//     return () => clearInterval(intervalId);
// }, []);