import React, { useEffect } from "react";
import axios from 'axios';

import belugaImage from '../images/beluga.jpg'; // Ensure this path is correct based on your project structure
import verifiedIcon from '../images/verified-icon.png'; // Ensure this path is correct based on your project structure
import userImage from '../images/user.png'; // Ensure this path is correct based on your project structure
import { useParams } from 'react-router-dom';
import pen from '../images/pen.png';
import InfoCard from './InfoCard';
import newMessage from '../images/newMessage_home.png';
import price from '../images/price_home.png';
import handshake from '../images/handshake-256.png';


const Home = () => {
 

    return (
        <>
    <div className="centered-home">

    <div className="title-card">
        <a href="/beluga" className="title">
            ReachMe<sup className="superscript-pro">pro</sup>
        </a>
    </div>

    <div className="support-container">
        <a href="/support" className="support-link">
            Help Center
        </a>
    </div>

        
        

        <div className="centered">
            <header> 

                <h1>Get in Touch with Your Favorite Creator Today!</h1>
                <p>Directly Chat with Beluga!<br/> Leave a message. Set your price. <br/>Only pay if you receive a response!</p>
                
                <a href="/form/beluga" className="cta-button">
                    Message Beluga
                    <img src={pen} alt="Pen icon" className="pen-icon"/>
                </a>
                
                

                <div className="profile">
                    <div className="profile-to">To</div>
                    <img src={belugaImage} alt="Beluga" className="profile-pic" />
                    <div className="profile-info">
                        <div className="profile-name">
                            Beluga <img src={verifiedIcon} alt="Verified" className="verified-icon" />
                        </div>
                        <div className="profile-desc">
                            10M+ followers
                        </div>
                    </div>
                </div>



                <div className="user-profile">
                    <div className="profile-to">From</div>
                    <img src={userImage} alt="User" className="user-profile-pic" />
                    <div className="profile-info">
                        <div className="profile-name">
                            You
                        </div>
                    </div>
                </div>

            </header>
        </div>
        <div className="glow-container">
            {/* <div className="glow blue-glow"></div> */}
            <div className="glow blue-glow"></div>
        </div>

        <div className="chat-container">
            <div className="vertical-rectangle">
                <div className="chat-header">
                    <img src={belugaImage} alt="Beluga" className="chat-profile-pic" />
                    <div className="chat-profile-info">
                        <div className="chat-profile-name">
                            Beluga <img src={verifiedIcon} alt="Verified" className="verified-icon" />
                        </div>
                        <div className="chat-profile-desc">
                            Online 3 hours ago
                        </div>
                    </div>
                </div>
                <div className="chat-messages">
                    <div className="chat-message user-message">
                        <p>Hey Beluga! Love your videos and wanted to ask...</p>

                        <img src={userImage} alt="User" className="message-profile-pic-user" />
                            
                        </div>
                    <div className="chat-message beluga-message">
                        <img src={belugaImage} alt="Beluga" className="message-profile-pic" />
                        <p>Hey! That's amazing! I actually wanted to let you know that...</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="info-cards-container">
            <InfoCard
                title={<>
                    <span>Chance to Chat with Beluga </span><span> One On One</span>
                </>}
                description="Ask your burning questions or express your appreciation and receive a personal response crafted just for you!"
                imageUrl={newMessage}
                cardClass="card-one"
            />
            <InfoCard
                title={<>
                    <span>Set Your Price,</span><br /><span>Know Your Odds</span>
                </>}
                description="Set the price you're willing to pay for a response. Our transparent system shows you the exact likelihood of getting a reply."
                imageUrl={price}
                cardClass="card-two"
            />
            <InfoCard
                title="Only Pay for Successful Interactions"
                description="Zero Risk! You’re only charged if you receive a response, ensuring that every penny spent leads to a genuine interaction."
                imageUrl={handshake}
                cardClass="card-three"
            />
        </div>


    </div>

        <style>
            {`
            @import url('https://fonts.googleapis.com/css2?family=Jockey+One&display=swap');

            // @keyframes glowing {
            //     0% { box-shadow: 0 0 5px #007BFF; }
            //     50% { box-shadow: 0 0 20px #007BFF; }
            //     100% { box-shadow: 0 0 5px #007BFF; }
            // }

           .support-link {
                color: #ccc; /* A contrasting color for visibility */
                font-size: 1rem; /* Slightly smaller than the title for emphasis differentiation */
                text-decoration: none; /* Remove underline */
                
                transition: color 0.1s ease; /* Smooth transition for hover effect */
                font-weight: bold;
                 position: absolute;
                  top: 30px;
                right: 30px;
                                z-index: 11;

            }

            .support-link:hover {
                color: #0056b3;
            }

            

            .pen-icon {
                margin-left: 7px; /* Adjust the space between text and icon */
                width: 20px; /* Adjust the size of the icon */
                height: 20px; /* Adjust the size of the icon */
                position: relative;
                top: 3px;
            }


            .blur-text {
            filter: blur(5px); /* Adjust the blur intensity as needed */
            -webkit-filter: blur(5px); /* For Safari */
            }
            .title-card {
                position: absolute;
                top: 20px;
                left: 20px;
                z-index: 10;
            }

            .title {
                font-family: 'Jockey+One', Jockey+One;
                font-size: 2rem;
                font-weight: bold;
                color: #fff;
                 text-decoration: none; /* Remove underline */
            }

            .superscript-pro {
                font-size: 0.4em;
                vertical-align: super;
                color: #007BFF;
                //orange
                //color: #445500;

            }

            .body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                background-color: #000;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                height: 100vh;
                position: relative;
            }
            
            .centered {
                margin-top: -12rem;
                text-align: center;
                position: relative;
                z-index: 1;
                max-width: 
            }
          .user-profile {
                position: absolute;
                left: 520px; /* Adjust this value to position the profile container */
                top: 310px; /* Adjust this value to position the profile container */
                display: flex;
                align-items: center;
                padding: 1rem;
                border-radius: 10px;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
                z-index: 2;
                flex-direction: column;
                text-align: center;
                transform: rotate(15deg);
                border-radius: 36px;
                background: #252525a1;

            }
            .user-profile-pic {
                width: 90px;
                height: 90px;
                border-radius: 50%;
                margin-bottom: -0rem;
                
            }
            .profile {
                position: absolute;
                left: 0px; /* Adjust this value to position the profile container */
                top: 210px; /* Adjust this value to position the profile container */
                display: flex;
                align-items: center;
                padding: 1rem;
                border-radius: 10px;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
                z-index: 2;
                flex-direction: column;
                text-align: center;
                transform: rotate(-15deg);
                border-radius: 55px;
                background: #252525a1;
            }

            .profile-to {
                position: absolute;
                top: -10px;
                left: -10px;
                background: #24272d;
                padding: 5px 10px;
                border-radius: 10px;
                font-size: 1rem;
                font-weight: bold;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            }

            .profile-pic {
                width: 140px;
                height: 140px;
                border-radius: 50%;
                margin-bottom: -0rem;
            }

            .profile-info {
                text-align: center;
            }

            .profile-name {
                font-size: 2rem;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .verified-icon {
                width: 20px;
                height: 20px;
                margin-left: 0.5rem;
            }

            .profile-desc {
                font-size: 1rem;
                color: #666;
                margin-top: 0.5rem;
            }

            header {
                position: relative;
                margin-bottom: 2rem;
                max-width: 700px;
                margin-left: auto;
                margin-right: auto;
            }

            header h1 {
                font-size: 3rem;
                margin-bottom: 1rem;
                font-weight: bold;
            }

            header p {
                font-size: 1.2rem;
                margin-bottom: 2rem;
                color: #aab;
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;
            }

            .cta-button {

                // background-color:#101010;
                background-color:#007BFF;
                // border: 2px solid #0056b3;    
                color: white;
                padding: .95rem 1.4rem;
                text-decoration: none;
                font-size: 1.2rem;
                border-radius: 25px;
                display: inline-block;
                position: relative;
                z-index: 4; /* Ensure it’s on top */
                cursor: pointer;
                line-height: 1.5rem;
                //animation: glowing 2.5s infinite;
            }

            .cta-button:hover {
                background-color: #0056b3;
            }

            .glow-container {
                position: relative;
                 margin-top: -60px;
                display: flex;
                justify-content: center;
                // width: 100%;
                z-index: 0;
                bottom: 25%;
                // max-width: 100%; /* Ensure it does not exceed the viewport width */
                // overflow: hidden; /* Hide any overflowing children */
                
                
            }

            .glow {
                width: 50%;
                height: 450px;
                border-radius: 50%;
                filter: blur(20px); /* Increased blur for a more subtle effect */
       
                margin: 100; /* Use negative margin to overlap the glows */
                // overflow: hidden; /* Hide any overflowing children */
            }

            .pink-glow {
                background: radial-gradient(circle, rgba(255,0,255,1) 0%, rgba(255,0,255,0.2) 50%, rgba(255,0,255,0) 100%);
            }

            .blue-glow {
                background: radial-gradient(circle, rgba(0, 123, 255, 1) 0%, rgba(0, 123, 255, 0.2) 50%, rgba(0, 123, 255, 0) 100%);
                overflow: hidden; /* Hide any overflowing children */
            }
            .orange-glow {
                background: radial-gradient(circle, rgba(255, 69, 0, 1) 0%, rgba(255, 69, 0, 0.2) 50%, rgba(255, 69, 0, 0) 100%);
            }




            .content {
                position: relative;
                z-index: 1;
                text-align: center;
            }

            .chat-container {
                position: absolute;
                top: 54%; /* Adjust this value to position the chat container */
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 3;
                
            }

            .vertical-rectangle {
                width: 300px;
                height: 350px;
                margin-top: -25px; /* Adjust this value to ensure it doesn't overlap the button */
    background: linear-gradient(to bottom, #373636da 20.5%, #1a1c20 12.5%, #1a1c20 70%, rgba(255, 255, 255, 0) 100%);
                border-radius: 30px;

                
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px;
                z-index: 1; /* Ensure it is below the button */
               
            }

            .chat-header {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                
            }

            .chat-profile-pic {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 10px;
            }

            .chat-profile-info {
                text-align: left;
            }

            .chat-profile-name {
                font-size: 1.2rem;
                font-weight: bold;
                display: flex;
                align-items: center;
            }

            .chat-profile-desc {
                font-size: 0.45rem;
                font-weight: bold;
                color: #2ECA0F;
            }

            .chat-messages {
                width: 100%;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
            }

            .chat-message {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }

            .user-message {
                justify-content: flex-end;
               
            }

            .beluga-message {
                justify-content: flex-start;
                padding-left: 8px; /* Add left padding to create space between the text and the profile picture */
                padding-right: 45px;
               
                
            }

            .beluga-message p {
                
                padding-left: 8px; /* Add left padding to create space between the text and the profile picture */
                padding-right: 0px;
                
            }
            

            .message-profile-pic {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 10px;
                padding:50px
          
                margin-top: -100px;
            }
            .message-profile-pic-user {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 1px;
                padding-left:100px
                // top: -10000px;                
                margin-top: -30px;
                margin-left: 5px;

          
            }

            .chat-message p {
                background: #5555;
                padding: 9px;
                border-radius: 20px;
                max-width: 50%;
                margin-top:3px;
            }

            .user-message p {
                background: #0056b3;
                position: relative;
                top: 6px;
                font-size: 1em;
                padding-left: 8px; /* Add left padding to create space between the text and the profile picture */
                padding-right: 10px;

            }

            
                

            }
            @media only screen and (max-height: 600px) {
            .title {
                font-family: 'Jockey+One', Jockey+One;
                font-size: 1rem;
                font-weight: bold;
                color: #ffff;
                }
            }
            @media (max-height: 650px) and (max-width: 800px) {
                .title-card {
                    display: none; /* Hide the title for screens with height less than 700px */
                }
                      header h1 {
                font-size: 3rem;
                margin-bottom: 1rem;
                font-weight: bold;
                 }
                    
                }

 @media only screen and (max-width: 620px) {

            .glow-container {
                position: relative;
                 margin-top: -60px;
                display: flex;
                justify-content: center;
                width: 100%;
                z-index: 0;
                bottom: 25%;
                height: 450px;
                filter: blur(120px)
       
                
             }
               
       
            }

            @media only screen and (max-width: 920px) {
            
            
             
            header h1 {
                
                    font-size: 3.2rem;
                    margin-bottom: 1rem;
                    font-weight: bold;
                    line-height: .95;
                }

                header p {
                    font-size: 1rem;
                    margin-bottom: 1rem;
                    color: #aab;
                    max-width: 400px;
                    margin-left: auto;
                    margin-right: auto;
                }
                .profile {
                position: absolute;
                left: -150px; /* Adjust this value to position the profile container */
                top: 210px; /* Adjust this value to position the profile container */
                display: flex;
                align-items: center;
                padding: 1rem;
                border-radius: 10px;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
                z-index: 2;
                flex-direction: column;
                text-align: center;
                transform: rotate(-15deg);
                border-radius: 55px;
                background: #252525a1;
            }
                .user-profile {
                position: absolute;
                left: 380px; /* Adjust this value to position the profile container */
                top: 310px; /* Adjust this value to position the profile container */
                display: flex;
                align-items: center;
                padding: 1rem;
                border-radius: 10px;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
                z-index: 2;
                flex-direction: column;
                text-align: center;
                transform: rotate(15deg);
                border-radius: 36px;
                background: #252525a1;

            }
                
            }


            @media only screen and (max-width: 920px) {
                header {
                position: relative;
                margin-bottom: 2rem;
                margin-top: 4rem;
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;
                }

                header h1 {
                
                    font-size: 2.5rem;
                    margin-bottom: 1rem;
                    font-weight: bold;
                }

                header p {
                    font-size: 1.2rem;
                    margin-bottom: 1rem;
                    color: #aab;
                    max-width: 400px;
                    margin-left: auto;
                    margin-right: auto;
                }
                
                

                .superscript-pro {
                    font-size: 0.3em;
                    vertical-align: super;
                    color: #007BFF;
                    
                }
                

            }

            @media only screen and (max-width: 600px) and (min-height: 840px) {
            .centered{
                margin-top: -15rem; /* Adjust this value to move elements up */
            }
            .chat-container,.glow-container  {
                margin-top: -2rem; /* Adjust this value to move elements up */
            }
           

            header h1 {
                font-size: 2.3rem;
                margin-bottom: 1rem;
                font-weight: bold;
                ma
                 }
            }
            

            @media only screen and (max-height: 800px) and (max-width: 400px) {
            header h1 {
                font-size: 2.4rem;
                margin-bottom: 1rem;
                font-weight: bold;
                max-width: 350px;
                  text-align: center;

                 }

                 .title {
                font-family: 'Jockey+One', Jockey+One;
                font-size: 1rem;
                font-weight: bold;
                color: #fff;
                 text-decoration: none; /* Remove underline */
            }
                 .support-link {
                font-size: .6rem; 
                top: 25px;
            }

            }

              @media only screen and (max-width: 760px) {
              .profile, .user-profile {
                display: none;
                }

                body {
                    padding-top: env(safe-area-inset-top);
                    padding-right: env(safe-area-inset-right);
                    padding-bottom: env(safe-area-inset-bottom);
                    padding-left: env(safe-area-inset-left);
                    background-color: #f3f3f3; /* Ensure the background matches your app's theme */
                    margin: 0;
                }




            `}
        </style>
        </>
    );
}

export default Home;
