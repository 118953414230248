import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import belugaImage from '../images/beluga.jpg'; // Ensure this path is correct based on your project structure
import verifiedIcon from '../images/verified-icon.png'; // Ensure this path is correct based on your project structure
import reactIcon from '../images/react_icon.png'; // Import the react icon image
import igLogo from '../images/ig_logo.png'; // Import Instagram logo
import ytLogo from '../images/youtube_logo.png'; // Import YouTube logo

const MessageStatus = () => {
  const { id } = useParams();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reaction, setReaction] = useState(null); // State to store the reaction
  const [showReactionOptions, setShowReactionOptions] = useState(false); // State to manage reaction options visibility
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);


  useEffect(() => {
    const fetchMessage = async () => {
      const response = await fetch(`https://reachme.onrender.com/message/${id}`);
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
        setReaction(data.message.reaction); // Load reaction from the database
      } else {
        console.error("Error fetching message:", data.error);
      }
      setLoading(false);
    };

    fetchMessage();
  }, [id]);

  const handleReactionClick = async (emoji) => {
    setReaction(emoji); // Set the selected reaction
    setShowReactionOptions(false); // Hide reaction options after selection

    // Update the reaction in the database
    try {
      const response = await fetch(`https://reachme.onrender.com/update-reaction`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, reaction: emoji }),
      });

      if (!response.ok) {
        throw new Error('Failed to update reaction');
      }

      const data = await response.json();
      if (!data.success) {
        throw new Error('Failed to update reaction');
      }
    } catch (error) {
      console.error('Error updating reaction:', error);
    }
  };

  
  const handleSmileyClick = () => {
    setShowReactionOptions(!showReactionOptions); // Toggle reaction options visibility
  };


  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };


  if (loading) {
    return <p>Loading...</p>;
  }

  if (!message) {
    return <p>Message not found.</p>;
  }




  const handleCancelClick = () => {
    setShowConfirmCancel(true);
  };

  const handleConfirmCancel = async () => {
    try {
      const response = await fetch(`https://reachme.onrender.com/cancel-order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      });

      if (!response.ok) {
        throw new Error('Failed to cancel order');
      }

      const data = await response.json();
      if (!data.success) {
        throw new Error('Failed to cancel order');
      }

      setShowConfirmCancel(false);
      // Optionally, redirect or update the state to reflect the canceled order
      setMessage(null);
      if (response.ok) {
        alert("Order successfully canceled. Check your email for confirmation!");
        }
    } catch (error) {
      console.error('Error canceling order:', error);
    }
    
  };

  const handleClosePopUp = () => {
    setShowConfirmCancel(false);
  };




  return (
    <>
    <div className="title-card">
         <a href="/beluga" className="title">
            ReachMe<sup className="superscript-pro">pro</sup>
        </a>
    </div>

      <div className="glow-container">
        <div className="glow blue-glow"></div>
        <div className="glow blue-glow"></div>
      </div>

      <div className="message-status-container">
        <div className="chat-header">
          <img src={belugaImage} alt="Creator" className="chat-profile-pic" />
          <div className="chat-profile-info">
            <div className="chat-profile-name">
              <a href="https://www.youtube.com/@Beluga1" target="_blank" rel="noopener noreferrer" className="profile-link">
                Beluga<img src={verifiedIcon} alt="Verified" className="verified-icon" />
              </a>
            </div>
            <div className="chat-profile-desc">
              Active Recently
            </div>
          </div>
          <div className="social-icons">
            <a href="https://www.instagram.com/beluga1000/?hl=en" target="_blank" rel="noopener noreferrer">
              <img src={igLogo} alt="Instagram" className="social-icon-ig" />
            </a>
            <a href="https://www.youtube.com/@Beluga1" target="_blank" rel="noopener noreferrer">
              <img src={ytLogo} alt="YouTube" className="social-icon" />
            </a>
          </div>
        </div>
        <div className="chat-messages">
        <div className="timestamp">{formatDate(message.userTimestamp)}</div>

        <div className="chat-message user-message">
          <div className="message-content">
            <p>{message.message}</p>
          </div>
        </div>
          {!message.responded && (
             <div className="response-status">
             <p><strong>Response Status:</strong> Pending</p>
             <p><strong>Price:</strong> ${message.price}.00</p>
             <p><a href="#" className="cancel-order-link" onClick={handleCancelClick}>Cancel Order</a></p>
             </div>
            )}
            
        
          {message.responded && (
            <>
                          <div className="timestamp">{formatDate(message.adminTimestamp)}</div>

            <div className="creator-message-container">

              <img src={belugaImage} alt="Creator" className="creator-profile-pic" />
              <div className="chat-message creator-message">
                <div className="message-content">
                  <p>{message.response} </p>
                  {reaction && <div className="reaction">{reaction}</div>} {/* Display reaction */}
                </div>
                {!reaction && (
                  <img src={reactIcon} alt="React Icon" className="smiley-icon" onClick={handleSmileyClick} />
                )}
                {showReactionOptions && (
                  <div className="reaction-button">
                    <button onClick={() => handleReactionClick('❤️')}>❤️</button>
                    <button onClick={() => handleReactionClick('😂')}>😂</button>
                    <button onClick={() => handleReactionClick('👍')}>👍</button>
                    <button onClick={() => handleReactionClick('😮')}>😮</button>
                    <button onClick={() => handleReactionClick('😢')}>😢</button>
                  </div>
                )}
              </div>
            </div>
            </>
          )}
        </div>
      </div>
      {showConfirmCancel && (
        <div className="confirm-popup">
        <p>Are you sure you want to cancel the order?</p>
        <div className="popup-buttons">
            <button onClick={handleConfirmCancel} className="popup-button confirm">Yes</button>
            <button onClick={handleClosePopUp} className="popup-button cancel">No</button>
        </div>
    </div>
      )}
      <style>{`

        @keyframes glowing {
          0% { box-shadow: 0 0 5px #007BFF; }
          50% { box-shadow: 0 0 20px #007BFF; }
          100% { box-shadow: 0 0 5px #007BFF; }
        }



    
      
          .confirm-popup {
            background: #1B1B20; /* Dark background */
            color: #fff; /* Light text */
            border: 1px solid #0080ff;

            padding: 20px;
            border-radius: 10px;
            max-width: 150px;
            margin: 0 auto;
            text-align: center;
            z-index: 1000;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

.confirm-popup p {
    font-size: 1rem;
    margin-bottom: 20px;
    background: #0000;
}

.popup-buttons {
    display: flex;
    justify-content: space-around;
}

.popup-button {
    background-color: #444;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.popup-button:hover {
    background-color: #1111;
}

.popup-button.cancel {
    background-color: #444; /* Different color for cancel button */
}

.popup-button.cancel:hover {
    background-color: #111; /* Darker shade on hover */
}

          .title-card {
                position: absolute;
                top: 20px;
                left: 20px;
                z-index: 10;
            }

            .title {
                font-family: 'Jockey+One', Jockey+One;
                font-size: 2rem;
                font-weight: bold;
                color: #fff;
                    text-decoration: none; /* Remove underline */

            }

            .superscript-pro {
                font-size: 0.4em;
                vertical-align: super;
                color: #007BFF;
            }
        .timestamp {
          text-align: center;
          color: #666;
          font-size: 0.8rem;
          margin-bottom: 5px;
        }
          
        .glow-container {
          position: relative;
          margin-top: -150px;
          display: flex;
          justify-content: center;
          width: 100%;
          z-index: 0;
          position: absolute; /* Centering */
          top: 70%; /* Centering */
          left: 50%; /* Centering */
          transform: translate(-50%, -50%); /* Centering */
        }

        .glow {
          width: 650px;
          height: 500px;
          border-radius: 50%;
          filter: blur(70px); /* Increased blur for a more subtle effect */
          margin: 0 -160px; /* Use negative margin to overlap the glows */
        }

        .pink-glow {
          background: radial-gradient(circle, rgba(255,0,255,1) 0%, rgba(255,0,255,0.2) 50%, rgba(255,0,255,0) 100%);
        }

        .blue-glow {
          background: radial-gradient(circle, rgba(0, 123, 255, 1) 0%, rgba(0, 123, 255, 0.2) 50%, rgba(0, 123, 255, 0) 100%);
        }
    
        .message-status-container {
          max-width: 600px;
          margin: 0 auto;
          padding: 20px;
          font-family: Arial, sans-serif;
          background-color: #fff; /* Dark background like Instagram DM */
          color: #fff; /* White text for better contrast */
          border-radius: 20px; /* Rounded edges */
          position: absolute; /* Centering */
          top: 50%; /* Centering */
          left: 50%; /* Centering */
          transform: translate(-50%, -50%); /* Centering */
          width: 100%;
          z-index: 1;
          
        }

        .chat-header {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          border-bottom: 1px solid #999;
          padding-bottom: 10px;
        }

        .chat-profile-pic {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .chat-profile-info {
          text-align: left;
          flex-grow: 1; /* Allow this to grow to push the social icons to the right */
        }

        .chat-profile-name {
          font-size: 1.2rem;
          font-weight: bold;
          display: flex;
          align-items: center;
          color: #000;
        }

        .chat-profile-name .profile-link {
          text-decoration: none; /* Remove underline */
          color: inherit; /* Remove link highlight */
        }

        .verified-icon {
          width: 15px;
          height: 15px;
          margin-left: 5px;
        }

        .chat-profile-desc {
          font-size: 0.9rem;
          color: #666;
        }

        .social-icons {
          display: absolute;
          gap: 10px;
        }

        .social-icon {
          width: 35px; /* Make the Instagram icon a bit bigger */
          height: 35px;
          cursor: pointer;
        }
        .social-icon-ig {
          width: 50px; /* Make the Instagram icon a bit bigger */
          height: 50px;
          cursor: pointer;
        }

        .chat-messages {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .chat-message {
          display: flex;
          align-items: flex-start;
          padding: 0px;
          border-radius: 20px;
          background-color: #262626; /* Darker bubble background */
          margin-bottom: 10px;
          position: relative; /* For positioning the smiley icon */
        }

        .user-message {
          align-self: flex-end;
          background-color: #007BFF; /* Blue bubble for user */
          text-align: left;
          color: #fff; /* White text in blue bubble */
          max-width: 50%;
          
        }

        .creator-message-container {
          display: flex;
          align-items: flex-start;
        }

        .creator-message {
          background-color: #eee; /* Grey bubble for creator */
          position: relative;
          max-width: 50%;
        }

        .creator-profile-pic {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .message-content {
          max-width: 100%;
        }

        .message-content p {
          margin: 5px 0;
          padding: 10px 15px;
          border-radius: 20px;
        }

        .user-message .message-content p {
          background-color: #007BFF; /* Blue background for user messages */
          color: white; /* White text */
        }

        .creator-message .message-content p {
          background-color: #eee; /* Dark background for creator messages */
          color: black; /* White text */
        }

        .message-content strong {
          color: #fff; /* White text */
        }

        .reaction-button {
          position: absolute;
          bottom: 54px; /* Position the reaction buttons above the message */
          right: -157px;
          display: flex;
          gap: 5px;
          background: #333; /* Background for the reaction button container */
          border-radius: 10px;
          padding: 5px;
        }

        .reaction {
          font-size: 1.2rem;
          margin-top: 10px;
          position: absolute;
          bottom: -12px;
          left: 8%;
          transform: translateX(-50%);
          background: #eee;
           padding: 1px 5px;
          border-radius: 100px;
        }

        .reaction-button button {
          background: none;
          border: none;
          cursor: pointer;
          font-size: 1.5rem;
          padding: 0;
        }

        .reaction-button button:focus {
          outline: none;
        }

        .smiley-icon {
          width: 20px; /* Adjust the size as needed */
          height: 20px; /* Adjust the size as needed */
          cursor: pointer;
          position: absolute;
          right: -25px;
          top: 50%;
          transform: translateY(-50%);
          transition: transform 0.2s;
        }

        .smiley-icon:hover {
          transform: translateY(-50%) scale(1.2); /* Highlight effect on hover */
        }
  
        .response-status {
           background-color: #2525258e;
           border: 1px solid #555;

          // color: #fff; /* Change this to the desired text color */
        
          padding: 10px;
          border-radius: 10px;
          max-width: 25%
          
        }

    

        .cancel-order-link{
        color: #ccc;
        font-size: 13px;
        
        }
        .cancel-order-link:visited {
            color: #cccc; /* Ensure the color stays the same after being visited */
          }


      @media only screen and (max-width: 650px) and (min-height: 680px) {
        .message-status-container {
        max-width: 300px;
        margin: 0 auto;
        padding: 20px;
        font-family: Arial, sans-serif;
        background-color: #fff; /* Dark background like Instagram DM */
        color: #fff; /* White text for better contrast */
        border-radius: 20px; /* Rounded edges */
        position: absolute; /* Centering */
        top: 100px; /* Start near the top of the page */
        left: 50%; /* Centering horizontally */
        transform: translateX(-50%); /* Centering horizontally */
        width: 100%;
        z-index: 1;
        overflow-y: auto; /* Allow vertical scrolling if content overflows */
        max-height: calc(100vh - 0%); /* Ensure container is scrollable if it overflows */
      }
        .message-content p {
        font-size: .9rem;
        margin: 3px 0;
        padding: 11px 11px;
        border-radius: 20px;

      }
      .user-message {
        align-self: flex-end;
        background-color: #007BFF; /* Blue bubble for user */
        text-align: left;
        color: #fff; /* White text in blue bubble */
        max-width: 58%;
      }
      .creator-message {
          background-color: #eee; /* Grey bubble for creator */
          position: relative;
          max-width: 58%;
      }
      }

       @media only screen and (max-width: 650px) and (max-height: 680px){
        .message-status-container {
        max-width: 300px;
        margin: 0 auto;
        padding: 20px;
        font-family: Arial, sans-serif;
        background-color: #fff; /* Dark background like Instagram DM */
        color: #fff; /* White text for better contrast */
        border-radius: 20px; /* Rounded edges */
        position: absolute; /* Centering */
        top: 100px; /* Start near the top of the page */
        left: 50%; /* Centering horizontally */
        transform: translateX(-50%); /* Centering horizontally */
        width: 100%;
        z-index: 1;
        overflow-y: auto; /* Allow vertical scrolling if content overflows */
        max-height: calc(100vh - 25%); /* Ensure container is scrollable if it overflows */
      }

      .message-content p {
        font-size: .9rem;
        margin: 3px 0;
        padding: 11px 11px;
        border-radius: 20px;

      }
      .user-message {
        align-self: flex-end;
        background-color: #007BFF; /* Blue bubble for user */
        text-align: left;
        color: #fff; /* White text in blue bubble */
        max-width: 58%;
      }
      .creator-message {
          background-color: #eee; /* Grey bubble for creator */
          position: relative;
          max-width: 58%;
      }
    }
      `}</style>
    </>
  );
};

export default MessageStatus;
