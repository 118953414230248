import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';


const ProtectedRoute = ({ element: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { admin_name } = useParams();


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.post('https://reachme.onrender.com/auth', { password, admin_name });
      if (response.data.authenticated) {
        setIsAuthenticated(true);
      } else {
        setError('Incorrect password');
      }
    } catch (error) {
      setError('Authentication failed');
    }
    setLoading(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isAuthenticated) {
    return <Component {...rest} />;
  }

  return (
    <div>
      <h2>Enter Password to Access Admin Dashboard</h2>
      <input 
        type="password" 
        value={password} 
        onChange={handlePasswordChange} 
      />
      <button onClick={handleLogin}>Submit</button>
      {error && <div>{error}</div>}
    </div>
  );
};

export default ProtectedRoute;
