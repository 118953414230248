import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import '../styles.css';
import { useParams } from 'react-router-dom';
import { SlArrowLeftCircle } from "react-icons/sl";




const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentForm = ({ clientSecret, email, name, handleSubmitForm }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleCheckout = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      console.error('Stripe.js or Elements has not loaded yet.');
      return;
    }

    console.log('Confirming setup intent...');
    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            email,
            name,
          },
        },
      },
      redirect: 'if_required',
    });

    if (error) {
      console.error('Error during card setup:', error);
    } else {
      // console.log('Setup Intent confirmed:', setupIntent);
      handleSubmitForm(setupIntent.payment_method);
    }
  };

  return (
    <form onSubmit={handleCheckout}>
      <PaymentElement />
      <button type="submit" className="button-payment" style={{ marginTop: "9px" }}>
        confirm
      </button>
    </form>
  );
};

const CreatorPage = () => {
  const [page, setPage] = useState(1);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState(7); // Default price value
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [customerId, setCustomerId] = useState("");
  const { admin_name } = useParams();
  const [messageError, setMessageError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);



  const closePopup = () => {
    setIsPopupVisible(false);
  };

  const handleSetupIntent = async () => {
    try {
      console.log('Creating setup intent...');
      const response = await fetch('https://reachme.onrender.com/create-setup-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const { clientSecret, customerId } = await response.json();
        // console.log('Setup intent created with client secret:', clientSecret);
        setClientSecret(clientSecret);
        setCustomerId(customerId);
      } else {
        const errorResponse = await response.json();
        console.error('Failed to create setup intent:', errorResponse);
      }
    } catch (error) {
      console.error('Error creating setup intent:', error);
    }
  };

  useEffect(() => {
    if (page === 3) {
      handleSetupIntent();
    }
  }, [page]);

  const handleSubmitForm = async (paymentMethodId) => {
    try {
      // console.log('Submitting form with data:', {
      //   email,
      //   name,
      //   message,
      //   price,
      //   paymentMethodId,
      //   customerId,
      //   admin_name
      // });

      const response = await fetch('https://reachme.onrender.com/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          name,
          message,
          price,
          paymentMethodId,
          customerId,
          admin_name
        }),
      });

      if (response.ok) {
        console.log("Message sent successfully!");
        alert("Message sent successfully! Check your email!");
        window.location.href = `${window.location.origin}/success`;
      } else {
        const errorResponse = await response.json();
        console.error("Failed to send message:", errorResponse);
        alert("Failed to send message.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };


  const handleNextPage = () => {
    if (page === 1) {
      if (!message) {
        setMessageError(true);
        return;
      }
      setMessageError(false);
      setPage(2);
    } else if (page === 2) {
      let valid = true;
      if (!name) {
        setNameError(true);
        valid = false;
      } else {
        setNameError(false);
      }
      if (!validateEmail(email)) {
        setEmailError(true);
        valid = false;
      } else {
        setEmailError(false);
      }
      if (valid) {
        setPage(3);
      }
    }
  };

  // const getIndicatorColorAndText = (value) => {
  //   if (value <= 9) {
  //     return { color: 'orange', text: 'Average chance of getting a reply!' };
  //   } else if (value <= 19){
  //     return { color: 'green', text: 'Very High chance of getting a reply' };
  //   }
  //   else{
  //     return { color: '#007BFF', text: 'GUARANTEED REPLY!!' };
  //   }
  // };

  // const { color, text } = getIndicatorColorAndText(price);

  // const getSliderBackground = (value) => {
  //   if (value <= 9) {
  //     return `linear-gradient(90deg, orange ${(value)*5}%, #d3d3d3 ${(value)*5}%)`;
  //   } else if (value <= 19){
  //     return `linear-gradient(90deg,green 20%, green ${(value)*5}%, #d3d3d3 ${(value)*5}%)`;
  //   }
  //   else {
  //     return `linear-gradient(90deg,#007BFF 20%, #007BFF ${(value)*5}%, #d3d3d3 ${(value)*5}%)`;
  //   }
  // };
  
  const getIndicatorColorAndText = (value) => {
    if (value < 4) {
      return { color: '#ff4747', text: 'Low chance of getting a reply!' };
    }
    else if (value < 5) {
      return { color: 'orange', text: 'Average chance of getting a reply!' };
    } 
    else if (value < 6) {
      return { color: '#1eb83f', text: 'Good chance of getting a reply!' };
    } 
    else if (value < 7) {
      return { color: '#1eb83f', text: 'Good chance of getting a reply!' };
    } else {
      return { color: '#0080ff', text: 'Guaranteed Reply!' };
    }
  };
  
  const { color, text } = getIndicatorColorAndText(price);
  const getSliderBackground = (value) => {
    if (value <= 4) {
      return `linear-gradient(90deg, orange ${((value - 3) / 4) * 100}%, #ffffff ${((value - 3) / 4) * 100}%)`;
    } else if (value <= 6) {
      return `linear-gradient(90deg, #1eb83f ${((value -3) / 4) * 100}%, #ffffff ${((value - 4) / 4) * 100}%)`;
    } else {
      return `linear-gradient(90deg, #0080ff ${((value - 3) / 4) * 100}%, #ffffff ${((value - 3) / 4) * 100}%)`;
    }
  };
  const sliderStyle = {
    background: getSliderBackground(price),
  };

  const maxChars = 500;



  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  
  const elementsStyle = {
    style: {
      base: {
        color: '#ffffff', // White text color
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4' // Light gray placeholder color
        }
      },
      invalid: {
        color: '#fa755a', // Red color for invalid input
        iconColor: '#fa755a'
      }
    }
  };
  
  

  return (
    <>
    {page != 3 &&
    <div className="title-card">
         <a href="/beluga" className="title-main">
            ReachMe<sup className="superscript-pro">pro</sup>
        </a>
    </div>
    }
      

      <div className="centered">
      <div className={`container ${page === 2 ? "page-2" : ""} ${page === 3 ? "page-3" : ""}`}>

          {page === 1 ?
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="title" style={{ paddingTop: 7 }}>Message Beluga</div>
                <img className="image" src={require('../images/beluga.jpg')} alt="" />
              </div>

              <div className="bubble" style={{ marginTop: "5px", marginBottom: "15px", paddingLeft: "15px",  paddingRight: "15px"}}>
                <div className="text">
                  We verify that all creator responses are high quality!
                </div>
              </div>

        


<div style={{ position: 'relative', marginBottom: '30px' }}>
  <textarea
    className={`textarea ${messageError ? 'textarea-error' : ''}`}
    placeholder="Write anything..."
    value={message}
    onChange={(e) => {
      setMessage(e.target.value);
      if (e.target.value) setMessageError(false);
    }}
    onFocus={() => setMessageError(false)}
    maxLength={maxChars}
    required
  />
  {messageError && (
    <div style={{ position: 'absolute', bottom: '-20px', left: '0', color: '#ff4444', fontSize: '0.8em' }}>
      Required
    </div>
  )}
  <div
    style={{
      position: 'absolute',
      bottom: 10, // Adjust as needed
      right: 10, // Adjust as needed
      fontSize: '0.8em',
      color: '#666',
    }}
  >
    {maxChars - message.length}
  </div>
</div>



                <div className="bubble" style={{ marginTop: "5px", marginBottom: "15px" }}>
                <div className="text">
                <span className="text-clickable">No Charge: </span>
                  Only pay if you receive a response!
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "15px" }}>
                <div style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "10px" }}>${price.toFixed(2)} USD</div>
                <input
                  type="range"
                  min="3"
                  max="7"
                  // step="2"
                  value={price}
                  onChange={(e) => setPrice(Number(e.target.value))}
                  className="slider"
                  style={{ width: "80%", ...sliderStyle }}
                />
                <div style={{ marginTop: "10px", color, fontWeight: "bold" }}>{text}</div> {/* Indicator text */}
              </div>

              <button type="button" className="button"  onClick={handleNextPage}>Next</button>
            </>

            : page === 2 ?
              <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                    type="button"
                    className="back-button"
                    onClick={handlePreviousPage}
                  >
                    <SlArrowLeftCircle size={30} />
                  </button>

                  <div className="title" style={{ paddingTop: 7 }}>Your details</div>
                  <img className="image" src={require('../images/beluga.jpg')} alt="" />
                </div>

<div style={{ position: 'relative', marginBottom: '30px' }}>
  <input
    type="text"
    className={`form ${nameError ? 'form-error' : ''}`}
    style={{ marginTop: "5px", marginBottom: "0px" }}
    placeholder="Your name"
    value={name}
    onChange={(e) => {
      setName(e.target.value);
      if (e.target.value) setNameError(false);
    }}
    onFocus={() => setNameError(false)}
    required
  />
  {nameError && <div style={{ color: '#ff4444', fontSize: '0.8em', position: 'absolute', bottom: '-20px', left: '3px' }}>Required</div>}
</div>

<div style={{ position: 'relative', marginBottom: '20px' }}>
  <input
    type="email"
    className={`form ${emailError ? 'form-error' : ''}`}
    placeholder="Email address"
    value={email}
    onChange={(e) => {
      setEmail(e.target.value);
      if (validateEmail(e.target.value)) setEmailError(false);
    }}
    onFocus={() => setEmailError(false)}
    required
  />
  {emailError && <div style={{ color: '#ff4444', fontSize: '0.8em', position: 'absolute', bottom: '-20px', left: '3px' }}>Enter Valid Email</div>}
</div>



                <div style={{ display: "flex", textAlign: "left" }}>
                  <div className="text-bold" style={{ marginTop: "10px", paddingLeft: "3px" }}>
                    You will get an email confirmation for your order and notification when Beluga replies.
                  </div>
                </div>

                <button type="button" className="button" style={{ marginTop: "52px", color: "white" }} onClick={handleNextPage}>
                  Checkout
                </button>

                
              </>

            : page === 3 && clientSecret ?
              <>
                 <div style={{ display: "flex", justifyContent: "space-between" }}>
               

                <button
                    type="button"
                    className="back-button-payment"
                    onClick={handlePreviousPage}
                  >
                    <SlArrowLeftCircle size={30} />
                  </button>


                  <div className="title-payment" style={{ paddingTop: 7 }}>Save Payment<br></br></div>
                  <img className="image" src={require('../images/beluga.jpg')} alt="" />
                  
               
                </div>
                <div className="payment-note">
                      <p>
                          No charge now. You pay only if you get a response. Cancel anytime!
                      </p>
                  </div>


                <div className="elements-wrapper">
                  <Elements stripe={stripePromise} options={{ clientSecret, appearance: elementsStyle }}>
                    <PaymentForm
                      clientSecret={clientSecret}
                      email={email}
                      name={name}
                      handleSubmitForm={handleSubmitForm}
                    />
                  </Elements>
                </div>
    
              </>
            : <></>}
        </div>
      </div>

      {/* Inline CSS for the slider */}
      <style>
        {`

        .button {
  margin-top: 25px; /* Default margin-top */
  color: #fff;
  background: ""; /* Add the appropriate background color if needed */
}
   .payment-note {
            text-align: center;
            color: #666;
            font-size: .9rem;
            line-height: 1;
            margin-left: 50px;
            margin-right: 50px;
            margin-top: -20px;
            margin-bottom: -20px;
        }

@media (max-width: 787px) {
  .button {
    margin-top: 10px; /* Smaller margin-top for screens narrower than 787px */
  }
}
  




             @keyframes glowing {
          0% { box-shadow: 0 0 5px #007BFF; }
          50% { box-shadow: 0 0 20px #007BFF; }
          100% { box-shadow: 0 0 5px #007BFF; }
        }
          .text-clickable{
          font-size: 1.23rem;
          color:#007BFF;
          }
          .text{
          font-size: 1rem;
          }
          
        
        .glow-container {
          position: relative;
          margin-top: -150px;
          display: flex;
          justify-content: center;
          width: 100%;
          z-index: 0;
          position: absolute; /* Centering */
          top: 70%; /* Centering */
          left: 50%; /* Centering */
          transform: translate(-50%, -50%); /* Centering */
        }

        .glow {
          width: 450px;
          height: 350px;
          border-radius: 50%;
          filter: blur(90px); /* Increased blur for a more subtle effect */
          margin: 0 -0px; /* Use negative margin to overlap the glows */
        }

        .pink-glow {
          background: radial-gradient(circle, rgba(255,0,255,1) 0%, rgba(255,0,255,0.2) 50%, rgba(255,0,255,0) 100%);
        }

        .blue-glow {
          background: radial-gradient(circle, rgba(0, 123, 255, 1) 0%, rgba(0, 123, 255, 0.2) 50%, rgba(0, 123, 255, 0) 100%);
        }

          .slider {
            -webkit-appearance: none;
            width: 100%;
            height: 8px;
            border-radius: 5px;
            background: #d3d3d3;
            outline: none;
            opacity: 0.7;
            -webkit-transition: .2s;
            transition: opacity .2s;
          }

          .slider:hover {
            opacity: 1;
          }

          .slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: ${color};
            cursor: pointer;
          }

          .slider::-moz-range-thumb {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: ${color};
            cursor: pointer;
          }

          .elements-wrapper {
            width: 100%;
            max-height: 600px;
            margin: 0 auto;
          }

          .payment-form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .payment-form .StripeElement {
            width: 100%;
            margin-bottom: 10px;
          }

          .payment-form button {
            width: 100%;
            max-width: 200px;
          }

          .title-card {
                position: absolute;
                top: 20px;
                left: 20px;
                z-index: 10;

            }

            .title, .title-main{
                font-family: 'Jockey+One', Jockey+One;
                font-size: 2rem;
                font-weight: bold;
                color: #fff;
                    text-decoration: none; /* Remove underline */

            }

            .superscript-pro {
                font-size: 0.4em;
                vertical-align: super;
                color: #007BFF;
            }


             @media only screen and (max-width: 600px) {
             .title-main {
                font-family: 'Jockey+One', Jockey+One;
                font-size: 1.5rem;
                font-weight: bold;
                color: #fff;
                    text-decoration: none; /* Remove underline */

            }
              // .back-button{
              //     font-family: SFProMedium;
              //     color: var(--background);
              //     font-size: 10px;
              //     padding: 5px;
              //     padding-left: 9px;
              //     padding-right: 10px;
              //     border-radius: 50px;
              //     height: 35px;
              //     width: 12%;
              //     text-decoration: none !important;
              //     opacity: 1;
    
              // }
        }

        `}
      </style>
    </>
  );
};

export default CreatorPage;
