import React, { useState } from 'react';
import '../styles.css';

const SupportPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        // You can send the data to a server or display a success message
        setSubmitted(true);
    };

    return (
        <>
            <div className="support-page">
                <div className="title-card">
                    <a href="/beluga" className="title">
                        ReachMe<sup className="superscript-pro">pro</sup>
                    </a>
                </div>

                <div className="info-cards-container">
                    <h2>Contact Support</h2>
                    <p>
                        Please email us with any questions or concerns. We will get back to you as soon as possible! <br/>
                        <span className="email"><strong>Email: </strong></span><span className="email-address">reachmepro.team@gmail.com</span>
                    </p>
                </div>
            </div>

            <style>
                {`
                .support-page {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 40px 20px;
                    min-height: 100vh;
                }

                .email-address {
                    text-decoration: underline;
                    color: #fff; /* Ensures the email is visible with underline */
                }
                .email {
                    text-decoration: none;
                    color: #fff; /* Ensures the email is visible with underline */
                }

                .title-card {
                    position: absolute;
                    top: 20px;
                    left: 20px;
                    z-index: 10;
                }

                .title {
                    font-family: 'Jockey+One', Jockey+One;
                    font-size: 2rem;
                    font-weight: bold;
                    color: #fff;
                    text-decoration: none;
                }

                .superscript-pro {
                    font-size: 0.4em;
                    vertical-align: super;
                    color: #007BFF;
                }

                .info-cards-container {
                    text-align: center;
                    background: #1b1b20b6;
                    color: #fff;
                    padding: 30px;
                    margin: 0 auto;
                    border-radius: 30px;
                    width: 80%;
                    max-width: 600px;
                    border-style: solid;
                    border: 0.3px solid #333;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
                    box-sizing: border-box;
                    margin-bottom: 70px;
                }

                .info-cards-container h2 {
                    font-family: 'Inter', sans-serif;
                    font-weight: 550;
                    font-size: 2.5rem;
                    color: #fff;
                    margin-bottom: 20px;
                    margin-right: 15%;
                    margin-left: 15%;
                    text-align: center;
                }

                .info-cards-container p {
                    font-family: 'Inter', sans-serif;
                    font-weight: 300;
                    font-size: 1.2rem;
                    line-height: 1.5;
                    color: #ccc;
                    margin-right: 10%;
                    margin-left: 10%;
                    text-align: center;
                }

                @media only screen and (max-width: 600px) {
                    .info-cards-container {
                        width: 90%;
                        padding: 20px;
                    }

                    .info-cards-container h2 {
                        font-size: 2rem;
                    }

                    .info-cards-container p {
                        font-size: 1rem;
                    }
                }
                `}
            </style>
        </>
    );
};

export default SupportPage;
