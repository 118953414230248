import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


const AdminDashboard = () => {
  const [messages, setMessages] = useState([]);
  const [response, setResponse] = useState("");
  const [selectedMessage, setSelectedMessage] = useState(null);
  const { admin_name } = useParams();


  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await fetch(`https://reachme.onrender.com/messages?admin_name=${admin_name}`);
      const data = await response.json();
      if (Array.isArray(data)) {
        setMessages(data);
      } else {
        console.error('Unexpected response format:', data);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleReply = async (messageId) => {
    try {
      const res = await fetch('https://reachme.onrender.com/respond', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: messageId, response }),
      });

      if (res.ok) {
        // Fetch messages again to update the list
        fetchMessages();
        setSelectedMessage(null);
        setResponse("");
      } else {
        alert("Failed to send response.");
      }
    } catch (error) {
      console.error('Error sending response:', error);
    }
  };

  return (
    <div className="admin-d">
      <h1>Admin Dashboard</h1>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Message</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {messages.map((message) => (
            <tr key={message.id}>
              <td>{message.name}</td>
              <td>{message.email}</td>
              <td>{message.message}</td>
              <td>{message.price}</td>
              <td>
                <button onClick={() => setSelectedMessage(message)}>Reply</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedMessage && (
        <div>
          <h2>Reply to {selectedMessage.name}</h2>
          <textarea
            value={response}
            onChange={(e) => setResponse(e.target.value)}
            placeholder="Your response"
          />
          <button onClick={() => handleReply(selectedMessage.id)}>Send Response</button>
        </div>
      )}

      <style jsx>{`
      
      .admin-d {
          background-color: #f3f3f3;
          height: 1500px;
          padding: 40px;
      }
        body {
          font-family: Arial, sans-serif;
        }

        h1 {
          text-align: center;
          margin-bottom: 20px;
          color: black;
        }

        h2 {
          color: black;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 20px;
          color: black;

        }

        thead {
          background-color: #4444;
        }

        th, td {
          padding: 12px;
          text-align: left;
          border-bottom: 1px solid #ddd;
        }

        tr:hover {
          background-color: #dddd;
        }

        button {
          padding: 8px 12px;
          background-color: #4CAF50;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }

        button:hover {
          background-color: #45a049;
        }

        textarea {
          width: 100%;
          height: 100px;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }

        textarea:focus {
          border-color: #4CAF50;
          outline: none;
        }
      `}</style>
    </div>
  );
};

export default AdminDashboard;






